<template>
  <div class="index-home">
    <!-- <div class="inform">
      <span style="flex: 1">您有50000福利金可抵现金</span>
      <span class="look-button">去看看</span>
    </div> -->

    <div class="main-part" v-if="approveList">
        <div class="advertising">
            <img src="@/assets/img/index_topbg.png" alt="" />
        </div>
        <!-- 快捷支付 -->
        <div class="main-part-item" v-if="!approveList.length && supportFastpay">
            <div class="main-part-item-inner">
                <div class="apply_card">
                    <div style="flex: 1;display: flex;flex-direction:row;justify-content: center;align-items: center">
                        <img src="@/assets/img/icon4.png" alt="" /><span>极速便捷</span></div>
                    <div style="flex: 1;display: flex;flex-direction:row;justify-content: center;align-items: center">
                        <img src="@/assets/img/icon5.png" alt="" /><span>信息完善</span></div>
                    <div style="flex: 1;display: flex;flex-direction:row;justify-content: center;align-items: center">
                        <img src="../../assets/img/icon6.png" alt="" /><span>支付多样</span></div>
                </div>
                <div class="item-head">
                <span style="padding: 0px 2px; height: 12px; background-color: #E69B4A"
                ></span>
                    <span class="title">快捷支付</span>
                    <span class="rests">无忧报名</span>
                </div>
                <div class="item-body">
                    <div class="not-apply">
                        <!-- <div>最高授信额度（元）</div> -->
                        <div class="figure">
                            <!-- <span class="money">50,000</span> -->
                            <div class="figure-text">
                                <div>选择购买产品显示价格直接支付即可</div>
                                <div>显示信息完善提供多样的支付渠道</div>
                            </div>
                            <!-- <span class="apply-for-button" @click="toApplyFor()">去申请</span> -->
                            <button class="btn blue mt1" @click="toQuickPay()"
                                    style="padding:8px 25px;background: linear-gradient(90deg, #FFCE9B 0%, #E69A48 100%);border:0;border-radius:31px;color:#fff;font-size:12px">去申请</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- 先学后付 -->
        <div class="main-part-item" v-if="!approveList.length && supportXxhf">
            <div class="main-part-item-inner">
                <div class="apply_card">
                    <div style="flex: 1;display: flex;flex-direction:row;justify-content: center;align-items: center">
                        <img src="@/assets/img/icon4.png" alt="" /><span>信用赊买</span></div>
                    <div style="flex: 1;display: flex;flex-direction:row;justify-content: center;align-items: center">
                        <img src="@/assets/img/icon5.png" alt="" /><span>最长36期</span></div>
                    <div style="flex: 1;display: flex;flex-direction:row;justify-content: center;align-items: center">
                        <img src="../../assets/img/icon6.png" alt="" /><span>安全快捷</span></div>
                </div>
                <div class="item-head">
                <span style="padding: 0px 2px; height: 12px; background-color: #E69B4A"
                ></span>
                    <span class="title">先学后付</span>
                    <span class="rests">让学习无压力</span>
                </div>
                <div class="item-body">
                    <div class="not-apply">
                        <!-- <div>最高授信额度（元）</div> -->
                        <div class="figure">
                            <!-- <span class="money">50,000</span> -->
                            <div class="figure-text">
                                <div>先享受学习服务后支付费用</div>
                                <div>保证资金安全和服务体验</div>
                            </div>
                            <!-- <span class="apply-for-button" @click="toApplyFor()">去申请</span> -->
                            <button class="btn blue mt1" @click="toApplyFor()"
                                    style="padding:8px 25px;background: linear-gradient(90deg, #FFCE9B 0%, #E69A48 100%);border:0;border-radius:31px;color:#fff;font-size:12px">去申请</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- 助梦金小程序  -->
        <!--<div class="main-part-item" v-if="showXCX && isWeixin && supportZMJ">
            <div class="main-part-item-inner">
                <div class="apply_card">
                    <div style="flex: 1;display: flex;flex-direction:row;justify-content: center;align-items: center">
                        <img src="@/assets/img/icon1.png" alt="" /><span>持牌机构</span></div>
                    <div style="flex: 1;display: flex;flex-direction:row;justify-content: center;align-items: center">
                        <img src="@/assets/img/icon2.png" alt="" /><span>安全可靠</span></div>
                    <div style="flex: 1;display: flex;flex-direction:row;justify-content: center;align-items: center">
                        <img src="../../assets/img/icon3.png" alt="" /><span>产品多样</span></div>
                </div>
                <div class="item-head">
          <span
                  style="padding: 0px 2px; height: 12px; background-color: #E69B4A"
          ></span>
                    <span class="title">助梦金</span>
                    <span class="rests">助力梦想</span>
                </div>
                <div class="item-body">
                    <div class="not-apply">
                        <div class="figure">
                            <div class="figure-text">
                                <div>本产品由多家第三方正规持牌机构</div>
                                <div>提供多样化金融产品</div>
                            </div>
                            <div v-html="wxHtml"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
        <!-- 助梦金H5 -->
        <div class="main-part-item">
            <div class="main-part-item-inner">
                <div class="apply_card">
                    <div style="flex: 1;display: flex;flex-direction:row;justify-content: center;align-items: center">
                        <img src="@/assets/img/icon1.png" alt="" /><span>持牌机构</span></div>
                    <div style="flex: 1;display: flex;flex-direction:row;justify-content: center;align-items: center">
                        <img src="@/assets/img/icon2.png" alt="" /><span>安全可靠</span></div>
                    <div style="flex: 1;display: flex;flex-direction:row;justify-content: center;align-items: center">
                        <img src="../../assets/img/icon3.png" alt="" /><span>产品多样</span></div>
                </div>
                <div class="item-head">
          <span
                  style="padding: 0px 2px; height: 12px; background-color: #E69B4A"
          ></span>
                    <span class="title">助梦金</span>
                    <span class="rests">助力梦想</span>
                </div>
                <div class="item-body">
                    <div class="not-apply">
                        <div class="figure">
                            <div class="figure-text">
                                <div>本产品由多家第三方正规持牌机构</div>
                                <div>提供多样化金融产品</div>
                            </div>
                            <button class="btn blue mt1" @click="toApplyH5" style="padding:8px 25px;background: linear-gradient(90deg, #FFCE9B 0%, #E69A48 100%);border:0;border-radius:31px;color:#fff;font-size:12px">去申请</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div
          class="main-part-item"
          v-for="item in applyList"
          :key="item.tmp_save_id"
        >
          <div class="item-head">
            <span
              style="padding: 0px 2px; height: 12px; background-color: #1677ff"
            ></span>
            <span class="title">账单分期</span>
            <span class="rests">让分期更简单</span>
          </div>
          <div class="item-body">
            <div class="applied">
              <div class="apply-title">是否继续申请</div>
              <div class="apply-for-button" @click="continueApplyFor(item)">
                继续申请
              </div>
            </div>
            <div class="hint">
              <span><img src="@/assets/img/duihao.png" alt="" />信用赊买</span>
              <span><img src="@/assets/img/duihao.png" alt="" />最长36期</span>
              <span><img src="@/assets/img/duihao.png" alt="" />安全快捷</span>
            </div>
          </div>
        </div> -->
        <div
                class="main-part-item"
                v-for="item in approveList"
                :key="item.bill_id"
        >
            <div class="item-head">
          <span
                  style="padding: 0px 2px; height: 12px; background-color: #E69B4A"
          ></span>
                <span class="title">先学后付</span>
                <span class="rests">让学习无压力</span>
            </div>
            <div class="item-body">
                <div class="applied">
                    <div class="apply-title">申请审核中</div>
                    <div class="apply-hint">审核大约需要10-30分钟，请耐心等待</div>
                    <div class="apply-button">审核中</div>
                </div>
                <div class="hint">
                    <span><img src="@/assets/img/duihao.png" alt="" />信用赊买</span>
                    <span><img src="@/assets/img/duihao.png" alt="" />最长36期</span>
                    <span><img src="@/assets/img/duihao.png" alt="" />安全快捷</span>
                </div>
            </div>
        </div>

    </div>

    <confirm
      :show.sync="showContinue"
      subTitle="您有一个申请尚未完成、是否继续？"
      okText="继续申请"
      cancelText="重新申请"
      @ok="continueApplyFor"
      @cancel="newApplyFor"
    ></confirm>
  </div>
</template>

<script>
import { listApplication, isSupportZMJ } from "@/api/application.js";
import { transferAppletAuth } from "@/api/util.js";
import { isWeixin } from "@/utils/index.js";
import Confirm from "@/components/confirm";
export default {
  components: {
    Confirm,
  },
  data() {
    return {
      wxHtml: "",
      business_id: "",
      applyList: null, // 填写了一半 未提交
      approveList: null, // 待审核
      isWeixin: isWeixin(),
      showContinue: false,
      webUrl:
        "/pages/action/advertising/index/index?launch_id=1413781",
      // webUrl: "pages/index/index",
      showXCX:false,
      supportZMJ: false,
      supportXxhf: false,
      supportFastpay: false
    };
  },
  computed: {},
  created() {
    this.business_id = this.$route.query.business_id || "";
  },
  mounted() {
    this.initData();
    this.getZMJAuth()
    
  },
  methods: {
    async getZMJAuth(){
      if (this.$route.query.business_id == undefined) {
        return
      }
      localStorage.setItem("queryId", this.$route.query.business_id)
      const { code, data } = await isSupportZMJ({
        business_id: this.$route.query.business_id
      });
      if (code == 200) {
        let supportZMJtemp = data.is_support_zmj === 1
        this.supportXxhf =  data.is_support_xxhf === 1
        this.supportFastpay = data.is_support_fast_pay === 1
        if(this.isWeixin && supportZMJtemp){
          this.toDreamAid();
        }
      }
    },
    async initData() {
      const { code, data } = await listApplication();
      if (code == 200) {
        this.applyList = data.application || [];
        this.approveList = data.approve || [];
      }
    },
    toApplyH5() {
        // location.href="https://cdn.xjietiao.com/at/xyhd09xz/xyhd-wxxcxtz01/"
        // location.href="https://cdn.xjietiao.com/at/KLS-XZ/KLSwxxcxtz02/"
        location.href="https://cdn.xjietiao.com/at/klsh5HCKA/klsh5HCkaM11-2/"
    },
    toDreamAid() {
      var that = this
      const param = {
        currentWebUrl: encodeURIComponent(document.location.href),
      };
      transferAppletAuth(param).then((res) => {
        if (res.code == 200) {
          wx.config({
            debug: false,
            appId: res.data.app_id,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonce_str,
            signature: res.data.signature,
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
            openTagList: ["wx-open-launch-weapp"],
          });
          wx.ready(function () {
            that.showXCX = true
            that.supportZMJ = true
            wx.error(function (res) {
              console.log("err", res);
              that.showXCX = false
              that.supportZMJ = false
            });
          });
          this.getHtml("wx256017bc98813102");
        }
      });
    },
    getHtml(app_id) {
      console.log(app_id);
      let script = document.createElement("script");
      script.type = "text/wxtag-template";
      script.text = `<button class="btn blue mt1" style="padding:8px 25px;background: linear-gradient(90deg, #FFCE9B 0%, #E69A48 100%);border:0;border-radius:31px;color:#fff;font-size:12px">去申请</button>`;
      this.wxHtml = `<wx-open-launch-weapp style=""
                                  id="launch-btn"
                                    appid="${app_id}"
                                    path="${this.webUrl}"
                                  >${script.outerHTML}
                                </wx-open-launch-weapp>`;
      // this.$nextTick(() => {
      //   let launchButton = document.getElementById("launch-btn");
      //   launchButton.addEventListener("launch", function (e) {
      //     console.log("success", e);
      //   });

      //   launchButton.addEventListener("error", function (e) {
      //     console.log("fail", e);
      //   });
      // });
    },
    toQuickPay() {
      // 快捷支付
      if (this.business_id) {
        this.$router.push({
          path: "/quick",
          query: {
            business_id: this.business_id,
          },
        });
      } else {
        this.$router.push("/referralQuick");
      }
    },
    toApplyFor() {
      // 测试的逻辑：申请和继续申请分开
      // this.$router.push("/referral");

      // 上线的逻辑：点申请 再提示是否继续
      if (this.applyList && this.applyList.length) {
        // 提示
        this.showContinue = true;
      } else {
        this.newApplyFor();
      }
    },
    newApplyFor() {
      if (this.business_id) {
        this.$router.push({
          path: "/stages",
          query: {
            business_id: this.business_id,
          },
        });
      } else {
        this.$router.push("/referral");
      }
    },
    continueApplyFor(item) {
      item = item || this.applyList[this.applyList.length - 1];
      if (item) {
        this.$router.push({
          path: "/stages",
          query: {
            business_id: item.business_id,
            tmp_save_id: item.tmp_save_id,
          },
        });
      } else {
        this.newApplyFor();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.index-home {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .inform {
    width: 100%;
    height: 70px;
    background: linear-gradient(90deg, #c6e4ff, #caddff, #c6f1ff);
    border-radius: 14px;
    display: flex;
    align-items: center;
    padding: 0px 40px;
    font-size: 18px;
    color: #0066ff;
    .look-button {
      width: 122px;
      height: 42px;
      color: #996217;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(90deg, #ffebc7, #ffd7a1);
      box-shadow: 0px 2px 9px 0px rgba(58, 121, 238, 0.23);
      border-radius: 21px;
    }
  }
  .main-part {
    background-color: #000000;
    flex: 1;
    overflow: auto;
    .advertising {
      img {
        width: 100%;
        height: auto;
        border-radius: 14px;
      }
    }
    .main-part-item {
      margin:0 30px 50px 30px ;
      background: #ffffff;
      border-radius: 32px;
      .main-part-item-inner {
        background: #ffffff;
        border-radius: 32px;
        .apply_card {
          display: flex;
          flex-direction: row;
          background-image: url("../../assets/img/home_index_card_bg.png");
          background-size: cover;
          padding: 9px 0;
          span {
            color: #552C00;
            font-size: 24px;
          }
          img {
            width: 54px;
            height: 54px;
          }
        }
      }
      .item-head {
        display: flex;
        align-items: center;
        color: #333;
        padding: 36px 0px 30px;
        border-bottom: 1px solid #e6e6e6;
        .title {
          margin: 0px 20px;
          font-size: 36px;
          font-weight: 600;
        }
        .rests {
          padding: 0px 8px;
          background: rgba(255,192,100,0.15);
          border-radius: 4px;
          border: 1px solid #E69B4A;
          font-size: 24px;
          line-height: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #E69B4A;
        }
      }
      .item-body {
        padding: 20px 30px;
        .not-apply {
          .figure {
            padding: 20px 0px;
            display: flex;
            align-items: center;
            color: #333333;
            .figure-text {
              flex: 1;
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              line-height: 40px;
              margin-right: 10px;
            }
            .money {
              flex: 1;
              font-size: 97px;
              font-weight: 600;
            }
            .apply-for-button {
              height: 62px;
              padding: 0px 45px;
              display: flex;
              align-items: center;
              background: #1677ff;
              color: #fff;
              box-shadow: 0px 7px 13px 0px rgba(22, 119, 255, 0.32);
              border-radius: 31px;
            }
          }
        }
        .applied {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #333;
          .apply-title {
            color: #1677ff;
            font-size: 36px;
            font-weight: 500;
            margin-top: 10px;
          }
          .apply-hint {
            font-size: 12px;
            margin: 15px 0px;
          }
          .apply-for-button {
            margin: 20px;
            height: 62px;
            padding: 0px 45px;
            display: flex;
            align-items: center;
            background: #1677ff;
            color: #fff;
            box-shadow: 0px 7px 13px 0px rgba(22, 119, 255, 0.32);
            border-radius: 31px;
          }
          .apply-button {
            height: 62px;
            background: #708eb8;
            box-shadow: 0px 7px 13px 0px rgba(112, 142, 184, 0.32);
            border-radius: 31px;
            color: #ffffff;
            font-size: 24px;
            padding: 0px 92px;
            display: flex;
            align-items: center;
          }
        }
        .hint {
          margin-top: 34px;
          text-align: center;
          span {
            font-size: 20px;
            margin: 0 20px;
            color: #666666;
            display: inline-flex;
            align-items: center;
            img {
              width: 24px;
              height: 24px;
              vertical-align: middle;
              margin-right: 10px;
            }
          }
          &.not-apply {
            text-align: left;
            margin-top: 10px;
            span {
              margin: 0 40px 0 0;
            }
          }
        }
      }
    }
  }
}
</style>